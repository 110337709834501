import React from 'react'
import '../css/header.css'

function Header() {
  return (
    <div className='header'>
        Eli Engelhart
    </div>
  )
}

export default Header