import React from 'react'
import './css/about.css'
import node  from './static/nodejs.png'
import react from './static/react-edited.png'
import python from './static/python.png'
import java from './static/java.png'
import passport from './static/passport.png'
import git from './static/git.png'
import linux from './static/linux.png'
import postgres from './static/postgres.png'
import express from './static/express.png'
import django from './static/django.png'
import html from './static/html.png'
import css from './static/css.png'
import javascript from './static/javascript.png'


function About() {
  const aboutTitle = "<About/>"
  return (
    <div className='about-container'>
      <div className='skills'>
        <h1>Skills</h1>
        <div className='skills-images'>
          <img src={python} alt={"python"}/>
          <img src={javascript} alt={"javascript"}/>
          <img src={java} alt={"java"}/>
          <img src={html} alt={"html"}/>
          <img src={css} alt={"css"}/>
          <img src={react} alt={"react"}/>
          <img src={django} alt={"django"}/>
          <img src={node} alt={"node"}/>
          <img src={express} alt={"express"}/>
          <img src={passport} alt={"passport"}/>
          <img src={postgres} alt={"postgres"}/>
          <img src={git} alt={"git"}/>
          <img src={linux} alt={"linux"}/>
        </div>
      </div> 
      <div className='about-me'>
        <h1>{aboutTitle}</h1>
        <p>Hello, I'm a U.S. Navy veteran now turned Full-Stack Software Developer. I enjoy learning and the process brings me joy. Every day I strive to bring
            the same discpline and drive to my work that made me successful in the Navy. In my spare time I love spending time with my family and doing Brazilian Jiu-Jitsu.</p>
      </div>
    </div>
  )
}

export default About
