import '../css/projects.css'

const FullStackText = () => {
  return (
    <div className='full-stack-text'>
      <div>
        <h4>Frontend</h4>
        <ul>
          <li>Html</li>
          <li>CSS</li>
          <li>Javascript</li>
          <li>React</li>
        </ul>
      </div>
      <div>
        <h4>Backend</h4>
        <ul>
          <li>ExpressJS</li>
          <li>PassportJS</li>
          <li>PostgreSQL</li>
        </ul>
      </div>
    </div>
  )

}

export default FullStackText
