import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../css/carousel.css';
import { Navigation } from 'swiper/modules';



export default function Carousel({img1, img2}) {
  return (
    <>
      <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
        <SwiperSlide>
            <img className='project-image' src={img1} alt='project' />
        </SwiperSlide>
        <SwiperSlide>
            <img className='project-image' src={img2} alt='project' />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
