import React from 'react'
import Scrollbuttons from './components/Scrollbuttons'
import Greeting from './components/Greeting'
import './css/home.css'

function Home() {
  return (
    <div className='home'>
        <Greeting/> 
        <Scrollbuttons/>       
    </div>
  )
}

export default Home