import React, { useEffect, useState } from 'react'
import '../css/navbar.css'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

function Navbar({ color }) {

  return (
    <div className='nav'>
      <div className='navbar-container'>
        <ul className='navbar-list'>
          <li>
            <a href='https://www.linkedin.com/' target="_blank" rel="noopener noreferrer">
              <LinkedInIcon sx={{ fontSize: 45, color: color }} />
            </a>
          </li>
          <li>
            <a href='https://github.com/Theoryofrelativity1915' target="_blank" rel="noopener noreferrer">
              <GitHubIcon sx={{ color: color }} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar
