import React from 'react'
import './css/contact.css'
import ContactForm from './components/ContactForm'

function Contact() {
  return (
    <div className='contact' id='contact'>
      <ContactForm/>
    </div>
  )
}

export default Contact