import React, { useState, useEffect, useRef } from 'react'
import Navbar from './components/Navbar'
import Home from './Home'
import About from './About'
import Projects from './Projects'
import Contact from './Contact'
import './css/app.css'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import projectflow from './static/projectFlow.png'
import BirdClassification from './static/BirdClassification.PNG'


function App() {
  let scrollPage = useRef()
  const scrollToHome = () => {
    document.getElementById("home").scrollIntoView({ behavior: "smooth" });
  }
  const [scrolled, setScrolled] = useState(false);
  const [mobile, setMobile] = useState(false)
  const [arrow, setArrow] = useState(false)
  useEffect(() => {
    if (scrollPage.current.scrollTop > window.innerHeight * 3.3) {
      setArrow(true)
    }
    if ((scrollPage.current.scrollTop > window.innerHeight * .95 && scrollPage.current.scrollTop < (window.innerHeight * 1.7)) || scrollPage.current.scrollTop > window.innerHeight * 4.1) {
      setScrolled(true)
    }

    const onScroll = (event) => {
      setArrow(false)
      if (window.innerWidth <= 1101) {
        setMobile(true)
      }
      else {
        setMobile(false)
      }
      if (scrollPage.current.scrollTop > window.innerHeight * 3.3) {
        setArrow(true)
      }
      if ((scrollPage.current.scrollTop > window.innerHeight * .95 && scrollPage.current.scrollTop < (window.innerHeight * 1.7)) || scrollPage.current.scrollTop > window.innerHeight * 4.1) {
        setScrolled(true)
      }
      else {
        setScrolled(false)
      }
    }
    scrollPage.current.addEventListener("scroll", onScroll, true)

    return () => document.removeEventListener('scroll', onScroll, true)
  })



  return (
    <div class="scroll-container" ref={scrollPage}>
      <Navbar color={scrolled ? 'white' : 'teal'} />
      <div className='scroll-area' id='home'>
        <Home />
      </div>
      <div className="scroll-area" id='about'>
        <About />
      </div>
      <div>
        <Projects projectFlow={projectflow} BirdClassification={BirdClassification} />
      </div>
      <div className="scroll-area">
        <Contact />
      </div>
      <div className='scroll-arrow'>
        <KeyboardArrowUpIcon onClick={() => scrollToHome()} sx={arrow ? { "color": 'teal', 'background-color': 'white', 'border-radius': '50%' } : { 'color': 'white', 'background-color': 'teal', 'border-radius': '50%' }} />
      </div>

    </div>
  )
}

export default App
