import React from 'react'
import './css/projects.css'
import './css/scrollbuttons.css'
import FullStackText from './components/FullStackText'
import projectFlowImg from './static/projectFlow.png'
import projectFlowImg2 from './static/Architecture.png'
import Carousel from './components/Carousel'
import birdClassificationImg from './static/BirdClassification.PNG'
import cnn from './static/cnn.jpeg'
const projectFlowText = "This is a project management web application with full CRUD functionality and user authentication. Express was used to create the REST API and PostgreSQL was used for the database. Projects can be created, assigned a team of developers, assigned tickets, or deleted. Every ticket is assigned a developer; however, there is a live comment section on each ticket, if other team members have any input on that particular feature/task. "
const birdClassificationText = "A convolutional neural network capable of 83% accuracy on the test data set found here: https://www.kaggle.com/datasets/gpiosenka/100-bird-species ModelTesting.py can be used to visualize several of the images that and their predictions."

function Projects() {
  return (
    <>
      <div className='projects' id='projects'>
        <section>Projects</section>
        <div className='project'>
          <div className='information-column'>
            <section>ProjectFlow</section>
            <p>{projectFlowText}</p>
            <FullStackText />
            <div className='buttons-container'>
              <ul>
                <li><a href='https://projectflow.eliengelhart.com' target="_blank" rel="noopener noreferrer">View Live</a></li>
                <li><a href='https://github.com/Theoryofrelativity1915/ProjectFlow' target="_blank" rel="noopener noreferrer">View Code</a></li>
              </ul>
            </div>
          </div>
          <div className='image-column one'>
            <Carousel img1={projectFlowImg} img2={projectFlowImg2}/>
          </div>
        </div>
      </div>
      <div className='projects'>
        <section>Projects</section>
        <div className='project'>
          <div className='information-column'>
            <section className='bird-class-title'>Bird Classification</section>
            <p>{birdClassificationText}</p>
            <div className='full-stack-text'>
          <div>
            <h4>Frontend</h4>
            <ul>
              <li>Html</li>
              <li>CSS</li>
              <li>Javascript</li>
              <li>Tailwind</li>
              <li>React</li>
            </ul>
          </div>
          <div>
            <h4>Backend/ML</h4>
            <ul>
              <li>Tensorflow</li>
              <li>Python</li>
              <li>FastAPI</li>
              {/* <li>PostgreSQL</li> */}
            </ul>
          </div>
        </div>
            <div className='buttons-container'>
              <ul>
                <li><a href='https://birdclassification.eliengelhart.com' target="_blank" rel="noopener noreferrer">View Live</a></li>
                <li><a href='https://github.com/Theoryofrelativity1915/Bird_Classification' target="_blank" rel="noopener noreferrer">View Code</a></li>
              </ul>
            </div>
          </div>
          <div className='image-column two'>
            {/* <img className='project-image' src={birdClassificationImg} alt='projectflow' /> */}
            <Carousel img1={cnn} img2={birdClassificationImg}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default Projects
