import React from 'react'
import { Link } from 'react-scroll'
import '../css/scrollbuttons.css'
function Scrollbuttons() {
    const scrollTo = (section) => {
        if (section == 'about'){
            document.getElementById("about").scrollIntoView({behavior:"smooth"});
        }
        else if (section == 'projects'){
            document.getElementById("projects").scrollIntoView({behavior:"smooth"});
        }
        else{
            document.getElementById("contact").scrollIntoView({behavior:"smooth"});
        }
        
    }
  return (
    <div className='buttons-container'>
        <ul>
            <li><a onClick={() => scrollTo('about')}>About</a></li>
            <li><a onClick={() => scrollTo('projects')}>Projects</a></li>
            <li><a onClick={() => scrollTo('contact')}>Contact</a></li>

        </ul>
    </div>
  )
}

export default Scrollbuttons