import React from 'react'
import TypewriterV2 from './TypewriterV2'
import '../css/greeting.css'

function Greeting() {
  return (
    <>
    
      <div className='greeting-container'>
          <h2>Hi, I'm Eli. I make things.</h2>
      </div>
      <h2>
      <TypewriterV2/>
      </h2>
    </>
  )
}

export default Greeting