import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import EmailIcon from '@mui/icons-material/Email';
import '../css/contactform.css'
import '../css/scrollbuttons.css'

function ContactForm() {
  const form = useRef()
  const [sentModal, setSentModal] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    emailjs.sendForm('service_7fj7a2k', 'template_f8hluki', form.current, 'NrzkVvd3r4UvJ6wNu')
      .then(form.current.reset()).catch(err => console.log(err.text))
    setSentModal(true)
  }
  return (
    <div className="contact-form">
      <div className={sentModal ? 'message-sent' : 'message-not-sent'}>
        <EmailIcon sx={{ fontSize: 95, color: '#02aab0' }} />
        <section>Thanks for reaching out! I'll get back to you soon.</section>
        <div className='buttons-container'>
          <ul>
            <li><button type='submit' onClick={() => setSentModal(false)}>Close</button></li>
          </ul>
        </div>
      </div>
      <h2>Let's get in touch.</h2>
      <form onSubmit={onSubmit} id='form' ref={form}>
        <div className="name">
          <label className="form-label" htmlFor="name">
          </label>
          <input className="form-control" type="text" name='from_name' placeholder='Name' id="name" required />
        </div>
        <div className="email">
          <label className="form-label" htmlFor="email">
          </label>
          <input className="form-control" type="email" placeholder='Email' id="email" name='user_email' required />
        </div>
        <div className="message">
          <label className="form-label" htmlFor="message">
          </label>
          <textarea className="form-control" placeholder='Message' id="message" name='message' required />
        </div>
        <div className='buttons-container'>
          <ul>
            <li><button type='submit'>Send</button></li>
          </ul>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
