import React, { useRef, useEffect } from 'react';
import Typed from 'typed.js';

import Typewriter from 'typewriter-effect';

export default function TypewriterV2() {

  return (
    <>
      <Typewriter options={{ loop: true }}
        onInit={(myTypewriter) => {
          myTypewriter.typeString('I\'m a Frontend Developer.')
            .callFunction(() => {
            })
            .pauseFor(500)
            .deleteChars(19)
            .typeString("Backend Developer.")
            .pauseFor(500)
            .deleteChars(18)
            .typeString("Full-Stack Developer.")
            .pauseFor(500)
            .start();
        }}
      />
    </>
  )
}
